@import '../../../colors.scss';

.HomePageSecondComponentWrapper {
    margin: 48px 0px;
    height: 340px;
    background-color: $primary;

    .HomePageSecondComponentContainer {
        height: 340px !important;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .HomePageSecondComponentTitlesContainer {
            .HomePageSecondComponentTitle {
                color: $white !important;
                font-weight: bold !important;
            }

            .HomePageSecondComponentSubTitle {
                color: rgba($white, 0.8) !important;
                margin-top: 100px !important;
            }
        }

        .HomePageSecondComponentContentContainer {
            margin-top: 48px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .HomePageSecondComponentBoxContainer {
                .HomePageSecondComponentBoxTitle {
                    color: $white;
                    font-weight: bold;
                }

                .HomePageSecondComponentBoxDescription {
                    color: rgba($white, 0.8);
                }
            }

            .divider {
                height: 70px;
                width: 3px;
                background-color: $secondary;
                margin: 0px 24px;
            }
        }
    }

}