@import '../../../colors.scss';

.AboutUsSecondComponentTitlesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .AboutUsSecondComponentTitle {
        font-weight: bold;
        color: $black;
    }

    .AboutUsSecondComponentSubTitle {
        margin-top: 16px;
        text-align: center;
        color: rgba($black, 0.8);
    }
}

.AboutUsSecondComponentCardsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    margin: 32px 0px;

    .AboutUsSecondComponentCard {
        width: 350px;
        height: 230px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 24px;
        border-radius: 24px;
        transition: 0.5s ease-in-out;
        cursor: pointer;

        .AboutUsSecondComponentCardNumber {
            display: flex;
            align-items: center;

            .AboutUsSecondComponentCardNumberText {
                transition: 0.6s ease-in-out;
                color: $primary;
                font-weight: bold;
            }

            .AboutUsSecondComponentCardTitle {
                transition: 0.6s ease-in-out;
                white-space: nowrap;
                font-weight: bold;
                margin-left: 8px;
                color: $primary;
            }
        }
    }
}

.AboutUsSecondComponentCard:hover {
    background-color: rgba($secondary , 0.5) !important;

    .AboutUsSecondComponentCardNumberText {
        color: $white !important;
    }

    .AboutUsSecondComponentCardTitle {
        color: $white !important;
    }
}