@import '../../../colors.scss';

.footerWrapper {
    background-color: $primary;
    padding-top: 24px;

    .footerLinksContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .footerLink {
            color: $white;
            margin: 0px 16px;
            cursor: pointer;
        }
    }

    .footerCopyRight {
        color: $white;
        opacity: 0.8;
        text-align: center;
    }
}