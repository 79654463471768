@import '../../../colors.scss';


.HomePageFirstComponentWrapper {
    margin: 48px 0px;

    .HomePageFirstComponentTitlesContainer {
        text-align: center !important;

        .HomePageFirstComponentTitle {
            font-weight: bold !important;
            text-transform: capitalize !important;
            color: $primary;
        }

        .HomePageFirstComponentSubTitle {
            color: rgba($black , 0.8);
            font-weight: bold !important;
        }
    }

    .HomePageFirstComponentDataContainer {
        margin-top: 32px !important;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;

        .HomePageFirstComponentDataBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .HomePageFirstComponentDataImage {
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(to bottom right, $primary, $third);
                border-radius: 100px;
                width: 150px;
                height: 150px;

                img {
                    animation: shake 4s ease infinite;
                }
            }

            .HomePageFirstComponentDataImage2 {
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(to bottom right, $primary, $third);
                border-radius: 100px;
                width: 150px;
                height: 150px;

                img {
                    animation: moveAndDisappear 5s ease infinite;
                }
            }

            .HomePageFirstComponentDataText {
                font-weight: bold;
                margin-top: 8px;
            }
        }
    }
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px) rotate(-5deg);
    }

    50% {
        transform: translateX(5px) rotate(5deg);
    }

    75% {
        transform: translateX(-5px) rotate(-5deg);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes moveAndDisappear {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}