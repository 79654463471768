@import '../../colors.scss';

.ticketsWrapper{
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: -50px;
    margin-bottom: 100px;
    position: relative;
    z-index: 9999;
    padding: 16px;
    border-radius: 16px;
    .ticketProgressTable {
        width: 100%;
        margin: 0;
        position: relative;
        border-collapse: collapse;

        .tableHeader {
            padding: 16px;
            position: sticky;
            top: -16px;
            background-color: $primary;
            color: $white;
            text-align: center;
        }

        .tableHeader:nth-child(1) {
            border-radius: 10px 0px 0px 10px;
        }

        .tableHeader:nth-child(3) {
            border-radius: 0px 10px 10px 0px;
        }
    }

}