@import '../../../colors.scss';

.SystemSecondComponentWrapper {

    .SystemSecondComponentWrapperLottieContainer {
        position: sticky;
        top: 0;

    }

    .systemSecondComponentTitle {
        font-weight: bold !important;
        background: linear-gradient(to right, $secondary, $primary);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
        display: inline;
    }

    .systemSecondComponentContent {
        font-size: 18px !important;
        margin-top: 8px !important;
    }
}