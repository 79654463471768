@import '../../../colors.scss';

.SystemThirdComponentWrapper {
    background: linear-gradient(to right, $third, $secondary);
    padding: 24px 0px;
    margin-bottom: 32px;

    .SystemThirdComponentTitle {
        color: $white;
        font-weight: bold;
        text-align: center;
    }

    .SystemThirdComponentAccordion {
        color: $white !important;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .SystemThirdComponentAccordionSummary {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;
            font-size: 18px !important;
        }
    }
}