@import '../../colors.scss';

.signinWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: radial-gradient(circle at center, $primary, $secondary);

    .signInMessage {
        color: red;
        text-align: center;
    }

    .signinContainer {
        width: 30%;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.3);
        padding: 20px;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    }

    .signinSubmitButton {
        padding: 16px 0px;
        border-radius: 16px;
        background-color: $secondary !important;
        box-shadow: none !important;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    }

    .signinBackToHome {
        border: 2px solid $white;
        color: $white;
        padding: 8px 24px;
        border-radius: 100px;
        margin-top: 24px;
    }
}

.signinBackToHome:hover {
    background-color: $white !important;
    color: $primary;
}