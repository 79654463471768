@import '../../../colors.scss';

.systemFirstComponentContentTitle {
    font-weight: bold !important;
    background: linear-gradient(to right, $primary, $secondary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    display: inline;
}