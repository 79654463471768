@import '../../../colors.scss';

.navbarWrapper {
    background-color: $white !important;
    color: $primary !important;
}

.navbarButton {
    border-bottom: 2px solid $white !important;
    border-radius: 8px !important;

    .navbarText {
        color: $primary !important;
    }
}

.navbarButton:hover {
    transition: 0.5s ease-in-out !important;
    border-radius: 8px !important;
    background-color: $secondary !important;

    .navbarText {
        color: $white !important;
    }
}

.loginButton {
    background-color: rgba($primary, 0.8) !important;
    color: $white !important;
    border-radius: 8px !important;
    text-transform: capitalize !important;
}

.navbarButtonActive {
    border-bottom: 2px solid $primary;
}