@import '../../../colors.scss';

.AboutUsFirstComponentContainer {
    width: 100%;
    height: 60vh;
    position: relative;

    .AboutUsFirstComponentImage {
        width: 100%;
        height: 100%;
    }

    .AboutUsFirstComponentOverlay {
        position: absolute;
        top: 25%;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .AboutUsFirstComponentOverlayTitle {
            text-transform: uppercase;
            color: $white;
            font-weight: bold;
        }

        .AboutUsFirstComponentOverlaySubTitle {
            text-transform: capitalize;
            color: rgba($white , 0.8);
            margin: 32px 0px;
        }
    }
}