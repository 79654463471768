@import '../../../colors.scss';

.AboutUsThirdComponentWrapper {
    margin: 32px 0px;
    width: 100%;

    .AboutUsThirdComponentTab {
        font-size: 20px !important;
        text-transform: capitalize !important;
        font-weight: bold;
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
        border-bottom: 3px solid $primary;
    }

    .AboutUsThirdComponentImageContainer {
        width: 100%;
        height: 50vh;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}