@import '../../../colors.scss';

.AboutUsFourthComponentWrapper {
    margin: 48px 0px;
    height: 50vh;
    background: linear-gradient(to bottom right, $primary, $third);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .AboutUsFourthComponentTitle {
        color: $white;
        font-weight: bold;
    }

    .AboutUsFourthComponentSubTitle {
        margin-top: 16px;
        color: $white;
    }

    .AboutUsFourthComponentLink {
        margin-top: 32px;

        .AboutUsFourthComponentButton {
            color: $white;
            border: 1px solid $white;
            border-radius: 100px;
            padding: 12px 32px;
        }
    }
}

.AboutUsFourthComponentButton:hover {
    background-color: rgba($white, 0.8);
    border: 1px solid $white !important;
    color: $primary !important;
}