@import '../../colors.scss';

.contactUsWrapper {
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: -50px;
    margin-bottom: 100px;
    position: relative;
    z-index: 9999;
    padding: 16px;
    border-radius: 16px;

    .contactUsRandomNumber {
        color: $primary;
    }

    .phoneNumberInput {
        border: 1px solid $light-gray !important;
        padding: 6px 8px;
        border-radius: 0.25rem;

        .PhoneInputInput {
            border: none !important;
        }
    }


    .react-datepicker-wrapper {
        width: 100%;

        .dateLossInput {
            width: 100% !important;
            border: 1px solid $light-gray !important;
            padding: 8px;
            border-radius: 0.25rem;
        }
    }

    .submitButton {
        background-color: $secondary !important;
        color: $white !important;
        padding: 12px 0px;
    }
}

.css-4jnixx-MuiStack-root {
    padding: 0 !important;
}