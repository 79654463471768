@import '../../../colors.scss';

.ScenariosFirstComponentWrapper {
    margin: 48px 0px;

}

.ScenariosFirstComponentContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .ScenariosFirstComponentContent {

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .ScenariosFirstComponentContentImageContainer {
            padding: 16px !important;
            background-color: $primary;
            border-radius: 100%;
            width: 250px;
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);

            .ScenariosFirstComponentContentImage {
                animation: zoomInOut 8s ease-in-out infinite;
            }

        }
    }
}


@keyframes zoomInOut {

    0%,
    100% {
        transform: scale(0.9);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.8;
    }
}